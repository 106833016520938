.package-wrap {
    width: 212px;
    background: #ffffff;
    border: 1px solid #3d8ac6;
    border-radius: 3px;
    margin: 0 0 0 30px;
    padding: 39px 36px;
    display: flex;         // Turn this into a flex container
    flex-direction: column; // Set the direction to column

    &__title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #252525;
    }

    &__image {
        margin: 34px 0 5px 0;
        width: 214px;
        height: 100px;
        object-fit: contain;
    }

    &__btn {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #ffffff;
        width: 214px;
        height: 40px;
        background: #3d8ac6;
        border-radius: 4px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        margin-top: auto;   // Push the button to the bottom
        &:hover {
            transform: scale(1.2);
            transition: all 0.3s;
        }
    }

    &__description {
        font-family: 'Manrope';
        font-size: 16px;
        color: #333;
        margin-bottom: 20px;  // Add some space between the description and the button
    }
}
