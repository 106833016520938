.step-wrapper {
    width: 100%;

    .error-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #ff7b7b;
        display: flex;
        align-items: center;
        margin: 9px 0 0 0;

        img {
            width: 15px;
            height: 16px;
        }

        &__text {
            margin: 0 0 0 5px;
        }
    }

    &__delete-looses {
        display: flex;
        align-items: center;
        height: 87px;

        &.active {
            color: red;
            cursor: pointer;
        }
    }
    &__column {
        &:first-child {
            margin: 0 50px 0 0;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 5px 0;

        &.small {
            width: 820px;
        }
    }
    &__center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__column-container {
        display: flex;
        padding: 5px 0 20px 0;
    }
    &__input-wrap {
        width: 300px;
        margin: 0 0 30px 0;
        &:last-child {
            margin: 0;
        }
    }
    &__select-container {
        position: relative;
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 124px;

        &__small {
            width: 197px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }
    &__title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #252525;
        margin: 0 0 10px 0;
        width: 400px;
    }

    &__subtitle {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.1px;
        color: #252525;
        padding: 0px 0 12px 0;

        &.disabled {
            color: #8e949a;
        }
    }

    &__textarea {
        width: 432px;
        height: 115px;
        padding: 10px;
        background: #eef5ff;
    }
    &__wrap {
        border-bottom: 1px solid #9fafbc;
        padding: 30px 0;

        &:last-child {
            border-bottom: none;
        }
    }

    &__plus-wrap {
        position: relative;
        display: flex;
    }

    &__plus {
        background: #3d8ac6;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        margin: 0 0 0 20px;
        cursor: pointer;
    }

    &__plus-text {
        p {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.1px;

            color: #373737;
        }
    }

    .top-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'Manrope';
        font-style: normal;
        line-height: 26px;
        border-bottom: 2px solid #9fafbc;
        padding: 0 0 40px 0;
        &__title {
            font-weight: 600;
            font-size: 24px;
            color: #252525;
        }
        &__subtitle {
            font-weight: 500;
            font-size: 20px;
            color: #606060;
            margin: 15px 0 0 0;
        }

        .info {
            margin: 40px 0 0 0;
            display: flex;
            width: 100%;
            justify-content: space-between;

            &__item {
                display: flex;
                align-items: flex-start;
                font-family: 'Manrope';
                font-style: normal;
                padding: 7px 0 0 0;

                &-title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.1px;
                    color: #3d8ac6;

                    min-width: 135px;
                    max-width: 130px;

                    &.signature {
                        width: 134px;
                    }
                }
                &-name {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #606060;
                    padding: 0 0 0 10px;
                    min-height: 22px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    width: 100%;
                    text-overflow: ellipsis;

                    &.signature {
                        width: 43%;
                        height: 16px;
                        margin: 0px 0 0 17px;
                        border-bottom: 1px solid #606060;
                    }
                }

                &.signature {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
    .table-block {
        margin: 40px 0 0 0;
        .header {
            display: flex;

            &__item {
                background: #dcebff;
                width: 188px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                border-right: 1px solid #bababa;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;

                color: #252525;

                &:last-child {
                    border-right: none;
                }
            }
        }

        &__wrap {
            display: flex;
        }

        &__value {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            /* or 162% */
            width: 454px;
            padding: 38px 59px;
            color: #979797;
        }

        &__custom-field-title {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.1px;
            color: #252525;
            margin: 0 0 13px 0;
        }

        &__line {
            width: 100%;
            border-top: 2px solid #bababa;
        }

        &__min {
            display: flex;
            flex-wrap: nowrap;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 26px;
            color: #606060;
            padding: 40px 0 0 0;
            margin: 0 0 15px 0;
        }

        &__total {
            display: flex;
            flex-wrap: nowrap;
            font-family: 'Manrope';
            font-style: normal;
            line-height: 26px;
            font-size: 24px;
            padding: 0 0 20px 0;
            font-weight: 600;
            color: #3d8ac6;
            padding: 0 0 40px 0;
        }
        .row {
            display: flex;

            &__item {
                width: 188px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                padding: 15px 0;

                &.border {
                    border-top: 1px solid #d3d3d3;
                }
            }
        }
        .row-main {
            border-top: 2px solid #d3d3d3;

            &-withoutborder {
                border: none;
            }

            &-height {
                height: 55px;
            }
        }

        &__additiolan-notes {
            width: 100%;
            padding: 20px 0 0 0;

            textarea {
                min-height: 58px;
                max-height: 1575px;
                width: 100%;
                background: #ffffff;
                border: 1px solid #8e949a;
                border-radius: 5px;
                padding: 11px;
                width: calc(100% - 23px);
                resize: vertical;
                font-family: 'Manrope', sans-serif;
                overflow: hidden;
                text-overflow: ellipsis;

                &:focus {
                    outline: 1px solid #8e949a;
                }
            }
        }
    }
}
.question-mark {
    border: 1px solid #3d8ac6;
    border-radius: 50%;
    padding: 0px 6px;
    color: #3d8ac6;
    cursor: pointer;
}
.tooltip {
    position: absolute;
    left: 103px;
    bottom: 128px;
    width: 303px;
    height: 110px;
    background: #373737;
    border-radius: 8px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
        color: #c0bfbf;
        margin: 0px 0 0 -37px;
    }
    li {
        color: #ffffff;
        margin: 0 0 0 17px;
    }
}
