
.confirm-modal {
    position: relative;
    width: 451px;
    height: auto;
    background: rgb(255, 255, 255);
    position: fixed;
    z-index: 10000; /* Greater than the wrapper's z-index */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* centers the modal both vertically and horizontally */
    max-width: 90%; /* ensures the modal doesn't go beyond 90% of the viewport width on small screens */
    border: 1px solid #3d8ac6;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &__title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__subtitle {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6c6c6c;
        margin: 16px 0 0 0;
    }
    &__title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
    }
    &__close {
        width: 32px;
        height: 32px;
        background: #dcebff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3d8ac6;
        cursor: pointer;
        position: absolute;
        right: 14px;
        top: 14px;
    }
    &__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 55px 0 10px 0;
    }
    &__cancel {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        margin: 0 0 0 8px;
        color: #3d8ac6;
        background: white;
        border: 1px solid #3d8ac6;
        border-radius: 4px;
        padding: 10px 20px;
        width: 120px;
        cursor: pointer;
    }
    &__create {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        margin: 0 0 0 8px;
        background: #3d8ac6;
        border-radius: 4px;
        color: white;
        padding: 10px 20px;
        border: 1px solid #3d8ac6;
        width: 120px;
        cursor: pointer;
    }
}
