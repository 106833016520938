.new-quote {
    background: #f9f9f9;
    width: 100%;

    &__wrapper {
        padding: 0px 30px 30px 30px;
        max-width: 1000px
    }

    .btns {
        display: flex;
        justify-content: flex-end;

        &__btn {
            color: #3d8ac6;
            border: 1px solid #3d8ac6;
            border-radius: 5px;
            padding: 7px 32px;
            margin: 0 0 0 50px;
            cursor: pointer;

            &.disabled {
                color: #b7b7b7;
                border: 1px solid #b7b7b7;
                cursor: context-menu;
            }
        }
    }

    .steps {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 48px;
        width: 920px;
        background: #ffffff;
        border-radius: 3px;
        padding: 20px 40px;
        margin: 50px 0 0 0;
        position: relative;

        &__step {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        }

        &__number {
            position: absolute;
            top: -10px;
            z-index: 2;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            transition: all 0.3s;

            &.done {
                background: #3d8ac6;
                border: 2px solid #3d8ac6;
                color: #ffffff;
                cursor: pointer;
                transition: all 0.3s;
            }

            &.past {
                color: #5f8eca;
                border: 2px solid #3d8ac6;
                background: white;
                cursor: pointer;
                transition: all 0.3s;
            }

            &.active {
                color: #5f8eca;
                border: 2px solid #3d8ac6;
                width: 31px;
                font-weight: 600;
                height: 31px;
                background: #dcebff;
                top: -16px;
                font-size: 15px;
                cursor: context-menu;
                transition: all 0.3s;
            }

            &.inactively {
                background: #9fafbc;
                color: #ffffff;
                border: 2px solid #9fafbc;
                cursor: context-menu;
            }
        }
        &__line {
            margin: 0 0 20px 0;
            margin: 0 0 20px 0;
            position: absolute;
            width: 790px;
            background: #9fafbc;
            height: 2px;
            top: 26px;
            left: 97px;
        }
        &__active {
            margin: 0 0 20px 0;
            margin: 0 0 20px 0;
            position: absolute;

            background: #3d8ac6;
            height: 2px;

            z-index: 1;
        }
        &__title {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            margin: 21px 0 0 0;

            &.done {
                color: #252525;
            }

            &.active {
                color: #3d8ac6;
            }

            &.inactively {
                color: #9aa8b5;
            }
        }
    }

    &__main {
        background: white;
        margin: 30px 0 20px 0;
        padding: 30px;
        display: flex;
    }

    &__bottom-buttons {
        margin: 47px 0 0 0;
        display: flex;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #f9f9f9;
        padding: 10px 0 10px 0;
    }
    &__save {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        border: 1px solid #3d8ac6;
        color: #3d8ac6;
        padding: 10px 22px;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 0 0 20px;
        &:first-child {
            margin: 0;
        }
    }
    &__next {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        padding: 10px 22px;
        background: #3d8ac6;
        color: white;
        border-radius: 4px;
        margin: 0 0 0 20px;
        cursor: pointer;
    }
}
