.table {
    padding: 10px 30px;
    max-width: 1073px;

    .noresult {
        padding: 30px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            width: 128px;
        }

        &__text {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            margin: 23px 0 0 0;
            line-height: 60px;
            color: #252525;
        }
    }

    &__wrap {
        height: 465px;
    }

    .head {
        display: flex;

        &__wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #dcebff;
            padding: 12px;
            width: 154px;
            border-right: 1px solid #bababa;
            &:last-child {
                border-right: none;
            }
            img {
                width: 11px;
                height: 6.5px;
            }

            &.package {
                width: 300px;
            }
        }

        &__item-arrow {
            cursor: pointer;
            font-size: 22px;
            transform: rotate(90deg);
            color: #252525;
            transition: all 0.3s;
            &:hover {
                color: #3d8ac6;
                transition: all 0.3s;
            }
        }
    }
    .row {
        display: flex;
        position: relative;

        &__item {
            position: relative;
            margin: 10px 0 0 0;
            display: flex;
            height: 48px;
            align-items: center;
            justify-content: space-between;
            background: #ffffff;
            padding: 12px;
            width: 154px;
            overflow: hidden;
            border-right: 1px solid #e8e8e8;
            &:last-child {
                border-right: none;
            }

            &.action {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 29px;
                font-weight: 800;
                cursor: pointer;
            }
            &-popup {
                width: 128px;
                min-height: 51px;
                position: absolute;
                top: 52px;
                right: 109px;
                z-index: 1;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #252525;
                background: #ffffff;
                border: 1px solid #3d8ac6;
                box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                padding: 15px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
            }

            &-popupitem {
                cursor: pointer;
                margin: 4px 0;
                &:hover {
                    color: #3d8ac6;
                }
            }

            &.package {
                width: 300px;
            }
        }

        &__progress {
            background: #f1f1f5;
            border-radius: 3px;
            padding: 3px 5px;
        }
        &__completed {
            background: #7bff81;
            border-radius: 3px;
            padding: 3px 5px;
        }
    }
    &__paginate {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            border: 1px solid #dcebff;
            background: #ffffff;
            border-radius: 3px;
        }
        .li {
            font-size: 10px;

            border-left: 1px solid #dcebff;
            border-right: 1px solid #dcebff;
            cursor: pointer;
            a {
                width: 29.21px;
                height: 36.75px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.selected {
                background: #dcebff;
            }
        }
        .break {
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 29.21px;
            height: 36.75px;
        }

        .btn {
            font-size: 10px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .paginate {
        }
    }
}
