.header {
    background: #fff;
    width: 100%;

    &__profile-info {
        position: absolute;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border: 1px solid rgba(61, 138, 198, 0.5);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        width: 128px;
        height: auto;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 15px;
        top: 37px;
        right: 0;
        cursor: pointer;
        color: #8e949a;

        &.hover {
            color: #3d8ac6;
        }

        img {
            width: 15px;
        }
        

        @media (max-width: 768px) {
            top: auto;
            right: 10px; // Adjust the positioning as needed
            transform: none; // Reset transform
        }
    }

    &__header {
        height: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 60px;

        @media (max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start; /* align items to the start of the container */
            padding: 10px;
        }
        
    }

    &__title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        color: #252525;

    }

    &__profile {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

    }

    &__username {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.1px;
        color: #252525;

        @media (max-width: 768px) {
            display: none; // Hide username for small screens
        }
    }

    &__circle {
        width: 32px;
        height: 32px;
        background: #3d8ac6;
        border-radius: 50%;
        margin: 0 12px 0 20px;
        cursor: pointer;
    }

    &__avatar {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 50%;
    }

    &__arrow {
        height: 7px;
    }

    &__logout {
        margin: 0 0 0 10px;
    }

    &__dropdown-option {
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: #f0f0f0;
        }
        
        display: flex;
        align-items: center;
    }

    .icon-margin {
        margin-right: 8px;
    }
}
