.footer {
    min-height: 115px;
    background: #3d8ac6;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &__text {
        font-family: 'Manrope', sans-serif;
        font-weight: 300;
        color: white;
    }
}
