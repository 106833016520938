.total-premium {
    position: fixed;
    bottom:0;
    height: 75px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    margin: 24px 0 40px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;

    &__text {
        color: #373737;
    }

    &__value {
        color: #3d8ac6;
        margin: 0 0 0 10px;
    }
}
