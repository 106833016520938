.page_not_found {
    height: calc(100vh - 132px);
    width: 100%;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__image {
        width: 242px;
    }

    &__text {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 60px;
        color: #252525;
        margin: 53px 0 50px 0;
    }

    &__btn {
        color: #ffffff;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background: #3d8ac6;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 279px;
        height: 50px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.2);
            transition: all 0.3s;
        }
    }
}
