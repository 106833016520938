.sweet-loading {
    height: 100vh;
    position: fixed;
    top: 0px;
    z-index: 10001;
    left: 0;
    width: 100vw;
    background: black;
    opacity: 0.4;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        border-bottom: 2px solid rgb(35, 176, 230) !important;
        border-right: 2px solid rgb(35, 176, 230) !important;
        border-left: none !important;
        border-top: none !important;
    }
}
