.modal-wrapper {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    position: fixed;
    z-index: 9999;  /* Increased z-index to ensure it's on top */
    left: 0;
    top: 0;
    margin: 0;      /* Resetting margins */
    padding: 0;     /* Resetting padding */
    transform: translateZ(0); /* Establishing a new stacking context */
}

.modal {
    width: 324px;
    height: 172px;
    background: rgb(255, 255, 255);
    position: fixed;
    z-index: 10000; /* Greater than the wrapper's z-index */
    top: calc(100vh - 50% - 114px);
    left: calc(100vw - 50% - 170px);
    border: 1px solid #3d8ac6;
    border-radius: 8px;
    padding: 24px;
    .customInput {
        width: 326px;
    }

    &__title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
    }
    &__close {
        width: 32px;
        height: 32px;
        background: #dcebff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3d8ac6;
        cursor: pointer;
    }
    &__subtitle {
        margin: 12px 0 16px 0;
    }
    &__input {
        background: #ffffff;
        border: 1px solid #8e949a;
        border-radius: 4px;
        border-radius: 4px;
        padding: 10px 12px;
        width: 300px;
        margin: 16px 0 0 0;
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
    }
    &__cancel {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        margin: 0 0 0 8px;
        color: #3d8ac6;
        background: white;
        border: 1px solid #3d8ac6;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
    }
    &__create {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        margin: 0 0 0 8px;
        background: #3d8ac6;
        border-radius: 4px;
        color: white;
        padding: 10px 20px;
        border: 1px solid #3d8ac6;
        cursor: pointer;

        &.disabled {
            background: #b7b7b7;
            border: 1px solid #b7b7b7;
            cursor: context-menu;
        }
    }
}
