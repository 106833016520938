.quotes {
    background: #f9f9f9;
    width: 100%;

    .customInput {
        width: 200px;
    }
    .customInput__search-clear {
        position: absolute;
        color: #8e949a;
        top: 8px;
        right: 15px;
        transform: rotate(45deg);
        position: absolute;
        color: #8e949a;
        top: 2px;
        right: 12px;
        transform: rotate(45deg);
        font-size: 26px;
        font-weight: 100;
        cursor: pointer;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        padding: 47px 30px 7px 30px;
    }

    .filters {
        display: flex;
        align-items: center;
        justify-content: space-around;

        &__wrap {
            margin: 0 35px 0 0;
            position: relative;
            width: 200px;
        }

        &__input {
            padding: 10px 17px;
            border: 1px solid #8e949a;
            border-radius: 3px;
        }
        &__arrow {
            position: absolute;
            top: 16px;
            right: 17px;
            width: 6px;
        }
        .clear {
            &__button {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                color: #3d8ac6;
                border: 1px solid #3d8ac6;
                border-radius: 4px;
                margin: 0 35px 56px 0px;
                padding: 10px 39px;
                cursor: pointer;
            }
        }
    }

    .addnew {
        &__btn {
            background: #3d8ac6;
            border-radius: 4px;
            padding: 11px 33px;
            width: 146px;
            border: none;
            font-family: 'Manrope', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: white;
            cursor: pointer;
        }
    }
    .submit_quote {
        background: #3d8ac6;
        border-radius: 4px;
        padding: 5px 7px;
        border: none;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: white;
        cursor: pointer;
        margin-right: 5px;
    }
    .more_actions {
        background: #3d8ac6;
        border-radius: 4px;
        padding: 5px 7px;
        border: none;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: white;
        cursor: pointer;
    }
    .edit_quote {
        background: #3d8ac6;
        border-radius: 4px;
        padding: 5px 7px;
        border: none;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: white;
        cursor: pointer;
        margin-right: 5px;
    }
    .view_in_zendesk{
        background: #3d8ac6;
        border-radius: 4px;
        padding: 5px 7px;
        border: none;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: white;
        cursor: pointer;
        margin-right: 5px;
    }
}

.nav__top div {
    cursor: pointer;
}