.help-modal {
    position: relative;
    width: 430px;
    background: rgb(255, 255, 255);
    position: absolute;
    z-index: 10000; /* Greater than the wrapper's z-index */
    top: calc(100vh - 50% - 265px);
    left: calc(100vw - 50% - 215px);
    border: 1px solid #3d8ac6;
    border-radius: 8px;
    padding: 24px;
    font-family: 'Manrope';
    font-style: normal;
    color: #1d1d1d;

    .pdf {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 15px 0 0 0;
        overflow: hidden;

        &__size {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #9e9e9e;
        }

        &__download-image {
            cursor: pointer;
        }

        &__wrap {
            display: flex;
            align-items: center;
        }

        &__image {
            width: 40px;
            height: 40px;
            margin: 0 10px 0 0;
        }
    }

    &__congratulations {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin: 0 0 10px 0;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
    }

    &__close {
        color: #828282;
        font-size: 19px;
        cursor: pointer;
    }

    &__subtitle {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6c6c6c;
        margin: 15px 0;
    }

    &__subject {
        color: #1d1d1d;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        padding: 14px 14px;
        width: 93%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
    }

    .description {
        &__title {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin: 12px 0 4px 0;
        }

        &__textarea {
            font-family: 'Manrope';
            font-style: normal;
            width: 93%;
            height: 91px;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            resize: none;
            padding: 14px;
            &:focus {
                outline: 1px solid #8e949a;
            }

            &.red {
                border: 1px solid #ff7b7b;
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 29px 0 0 0;

        &__submit {
            background: #3d8ac6;
            border-radius: 4px;
            color: #ffffff;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }

        &__cancel {
            border: 1px solid #3d8ac6;
            color: #3d8ac6;
            border-radius: 4px;
            margin: 0 16px 0 0;
        }

        .btn {
            width: 103px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

.error {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #ff7b7b;
}
