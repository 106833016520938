.nav {
    background: white;
    border-right: 1px solid #3d8ac6;
    height: 100%;
    width: 265px;
    transition: width 0.3s ease;

    &.collapsed {
        width: 60px; // width when collapsed
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 10px;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: center; // This will center the logo horizontally
        height: 150px;
    }

    &__logo {
        margin: 0 35px 0 0;

        // Reset the margin and optionally set other styles when the nav is collapsed
        .collapsed & {
            margin: 0;
        }
    }

    &__navigation {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    &__image {
        width: 20px;
        padding: 0 20px;
        fill: black;
    }

    &__section-wrap {
        height: 52px;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
            background: #dcebff;
            border-radius: 3px 0px 0px 3px;
        }
    }

    &__addition {
        background: #5f8eca;
        width: 3px;
        height: 32px;
        border-radius: 0px 100px 100px 0px;
    }

    &__section {
        font-size: 14px;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        text-decoration: none;
        color: #8e949a;
        line-height: 19px;
        &.active {
            color: #252525;
        }
    }
}

.whiteBackground {
    width: 100%;
    height: 100vh;
    background: rgb(255, 255, 255);
    &__head {
        height: 150px;
    }
}
.greyBackground {
    width: 100%;
    height: 100%;
    background: #f9f9f9;
    &__head {
        height: 150px;
        background: rgb(255, 255, 255);
    }
}

.nav {
    background: white;
    border-right: 1px solid #3d8ac6;
    height: 100%;
    width: 265px;
    transition: width 0.3s ease;

    &.collapsed {
        width: 60px; // width when collapsed
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 10px;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: center; // This will center the logo horizontally
        height: 150px;
    }

    &__logo {
        margin: 0 35px 0 0;

        // Reset the margin and optionally set other styles when the nav is collapsed
        .collapsed & {
            margin: 0;
        }
    }

    &__navigation {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    &__image {
        width: 20px;
        padding: 0 20px;
        fill: black;
    }

    &__section-wrap {
        height: 52px;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
            background: #dcebff;
            border-radius: 3px 0px 0px 3px;
        }
    }

    &__addition {
        background: #5f8eca;
        width: 3px;
        height: 32px;
        border-radius: 0px 100px 100px 0px;
    }

    &__section {
        font-size: 14px;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        text-decoration: none;
        color: #8e949a;
        line-height: 19px;
        &.active {
            color: #252525;
        }
    }
}

.whiteBackground {
    width: 100%;
    height: 100vh;
    background: rgb(255, 255, 255);
    &__head {
        height: 150px;
    }
}
.greyBackground {
    width: 100%;
    height: 100%;
    background: #f9f9f9;
    &__head {
        height: 150px;
        background: rgb(255, 255, 255);
    }
}

.container {
    width: 100%;
    display: grid;
    grid-template-columns: 265px 1fr; /* Only two columns: nav width and remaining width */

    &.collapsed {
        grid-template-columns: 60px 1fr; /* Collapsed nav width and remaining width */
    }
}


