.homepage {
    width: 100%;
    background: #f9f9f9;

    &__packages {
        display: flex;
    }

    &__title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 26px;
        color: #252525;
        margin: 50px 0 42px 31px;
    }
}
