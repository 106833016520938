.popup {
    width: 350px;
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.1px;
    color: #ffffff;
    position: fixed;
    top: 150px;
    right: -500px;
    z-index: 7;
    transition: all 0.4s;

    &__text {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.1px;
        color: #ffffff;
    }

    &.active {
        right: 100px;
        transition: all 0.4s;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__mark-success {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        height: 20px;
        background: white;
        border-radius: 50%;
        color: #4ce152;
        font-size: 14px;
        margin: 0 10px 0 0;
    }
    &__mark-error {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #ff4444;
        font-size: 16px;
        font-weight: 600;
        margin: 0 10px 0 0;
    }

    &.success {
        background: #4ce152;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }
    &.error {
        background: #ff4444;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        bottom: 110px !important;
    }
}
