.custom-field {
    position: relative;
    min-height: 94px;

    &__dollar {
        font-size: 13px;
        position: absolute;
        top: 10px;
        left: 9px;

        &.disabled {
            color: #8e949a;
        }
    }
    &__smalldollar {
        font-size: 13px;
        position: absolute;
        top: 8px;
        left: 9px;

        &.disabled {
            color: #8e949a;
        }
    }

    &.login-field {
        min-height: 40px;
        max-height: 65px;
    }

    &.modal-field {
        min-height: 60px;
    }

    &__multi {
        display: flex;

        &.radio {
            padding: 2px 0 2px 20px;
        }
    }

    &-title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #373737;
        margin: 0 0 8px 0;

        &.disabled {
            color: #8e949a;
        }
    }

    &-smalltitle {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #373737;
        margin: 0 0 8px 0;
    }

    &__list {
        position: absolute;
        width: 99%;
        background: #ffffff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 3px 3px;
        max-height: 123px;
        top: 41px;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 12px; /* ширина всей полосы прокрутки */
        }

        &::-webkit-scrollbar-track {
            background: white; /* цвет зоны отслеживания */
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dcebff; /* цвет бегунка */
            border-radius: 20px; /* округлось бегунка */
            border: 3px solid white; /* отступ вокруг бегунка */
        }

        &-multi {
            position: absolute;
            z-index: 2;
            background: white;
            height: auto;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

            &.top {
                top: -311px;
            }

            .multi-checkbox {
                cursor: pointer;
                margin: 0 10px 0 0;

                .none {
                    display: none;
                }
            }
        }
    }
    &__item {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #4a4949;
        padding: 5px 9px;
        cursor: pointer;

        &.completed {
            color: #22bd28;
        }

        &:hover {
            background: #3e96f3;
            color: white;
        }
    }

    .errortext {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 3px 0;
        height: 60px;

        &__text {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #ff7b7b;
            margin: 0 0 0 5px;
        }
    }

    .inputWithImage {
        padding: 11px 11px 11px 35px;
        width: calc(100% - 46px);
    }
    &__input {
        background: #ffffff;
        border: 1px solid #8e949a;
        border-radius: 5px;
        padding: 11px;
        width: calc(100% - 22px);

        &.dollar {
            padding: 11px 11px 11px 17px;
            width: calc(100% - 28px);
        }

        &.multiSelect {
            padding: 11px 35px 11px 11px;
            width: calc(100% - 47px);
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
        &::placeholder {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            color: #8e949a;
        }

        &:focus {
            border-radius: 5px;
            outline: 1px solid #8e949a;
        }
        &.red {
            border: 1px solid #ff7b7b;
        }
        &.search {
            padding: 11px 11px 11px 35px;
            border: none;
            outline: none;
            background: #dcebff;
            &:focus {
                background: white;
            }
        }
    }

    &__selection {
        background: #ffffff;
        border: 1px solid #8e949a;
        border-radius: 5px;
        padding: 11px 11px 11px 17px;
        width: calc(100% - 28px);
        height: 16px;

        &.small {
            .value {
                margin: -4px 0 0px 0;
            }
            .placeholder {
                margin: -4px 0 0px 0;
            }
        }

        .value {
            font-size: 13px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .placeholder {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            color: #8e949a;
        }
    }

    &__image {
        position: absolute;
        left: 14px;
        top: 12px;
        width: 13px;
    }

    &__searchImage {
        position: absolute;
        top: 12px;
        left: 11px;
    }
    &__arrow {
        position: absolute;
        top: 4px;
        right: 13px;
        font-size: 23px;
        transform: rotate(90deg);
        color: #8e949a;
        cursor: pointer;
    }
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin: 20px 0;

    &__title {
        &.disabled {
            color: #8e949a;
        }
    }

    &__checkbox {
        margin: 0 15px 0 0;
        cursor: pointer;
    }

    &:first-child {
        margin: 0;
    }
    &:last-child {
        margin: 0;
    }
}

.smallDatepicker {
    height: 10px;
}
