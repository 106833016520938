.login {
    background: #f9f9f9;
    width: 100%;
    height: 100vh;
    font-family: 'Manrope', sans-serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    &__top-wrap {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100vh;
        margin: 3% 0 0 0;
        width: 1400px;

        @media (max-width: 1550px) {
            width: 1100px;
        }
        @media (max-width: 1300px) {
            width: 1000px;
        }
        @media (max-width: 1110px) {
            width: 900px;
        }
    }

    &__error-block {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #ffffff;
        background: #ff7b7b;
        border-radius: 3px;
        padding: 11px 8px;
        width: 339px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 30px 0;
    }

    .error-icon {
        margin: 5px;
    }

    .custom-field {
        width: 338px;
        margin: 6px;
    }

    &__form {
        width: 338px;
    }

    &__logo {
    }
    &__back-btn {
        margin: 27px 0 0 0;
        color: #3d8ac6;
        border: 1px solid #3d8ac6;
        background: inherit;
        border-radius: 4px;
        padding: 16px 60px;
        font-size: 16px;
        font-family: 'Manrope', sans-serif;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
        transition: all 0.3s;
        text-decoration: none;

        &:hover {
            transform: scale(1.2);
            transition: all 0.3s;
        }
    }

    &__mainblock {
        width: 337px;
        min-height: 321px;
        background: #ffffff;
        border: 1px solid #3d8ac6;
        border-radius: 10px;
        padding: 56px 46px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__image {
        width: 24px;
        margin: 0 0 25px 0;
    }
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        padding: 0 0 35px 0;
    }

    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__input {
        margin: 0 0 16px 0;
    }

    &__btn {
        background: #3d8ac6;
        padding: 11px 105px 11px 105px;
        font-family: 'Manrope', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        font-style: normal;
        border-radius: 4px;
        border: none;
        margin-top: 30px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.2);
            transition: all 0.3s;
        }
    }
    &__forgot-btn {
        background: white;
        border: none;
        color: #3d8ac6;
        font-family: 'Manrope', sans-serif;
        font-weight: 400;
        margin-top: 15px;
        height: 18px;
        cursor: pointer;
        text-decoration: none;
    }
}
