html {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    padding: 0;
}


code {
    font-family: 'Manrope', sans-serif;
}
.App {
    display: flex;
    justify-content: center;
}

.wrapper-white {
    background: #fff;
    width: 39%;
}
.wrapper {
    background: #f9f9f9;
    width: 61%;
}

.wrap {
    display: flex;
    position: absolute;
    height: 100vh;
    z-index: -1;
    width: 100vw;
}
.mandatory {
    font-size: 16px;
    color: grey;
}
